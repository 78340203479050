import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import classnames from 'classnames';
import Section from '../section';
import ComposedLabel from '../composed-label';
import withTracker from '../with-tracker';

const namespace = 'regret';

const Regret = ({ type, elements }) => (
  <Section type={type}>
    <div className={classnames('container', `${namespace}__container`)}>
      {elements.map((element) => (
        <div key={element.text} className={`${namespace}__box`}>
          <ComposedLabel className={`${namespace}__content`} {...element} />
        </div>
      ))}
    </div>
  </Section>
);

Regret.propTypes = {
  type: string.isRequired,
  elements: arrayOf(
    shape({
      text: string.isRequired,
      color: string,
      bg_color: string,
    }),
  ).isRequired,
};

export default withTracker(Regret);
